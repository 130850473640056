@font-face {
    font-family: 'Pro-Text-Light' ;
    src: url('./assets/fonts/SF-Pro-Text-Light.otf');
}
  
@font-face {
    font-family: 'Pro-Text-Medium' ;
    src: url('./assets/fonts/SF-Pro-Text-Medium.otf');
}
  
@font-face {
    font-family: 'Pro-Text-Regular';
    src: url('./assets/fonts/SF-Pro-Text-Regular.otf');
}
  
@font-face {
    font-family: 'Pro-Text-SemiBold' ;
    src: url('./assets/fonts/SF-Pro-Text-Semibold.otf');
}

:root{
    --Pro-Text-Light: 'Pro-Text-Light';
    --Pro-Text-Medium: 'Pro-Text-Medium';
    --Pro-Text-Regular: 'Pro-Text-Regular';
    --Pro-Text-SemiBold: 'Pro-Text-SemiBold';

    --Color-Negro: #000;
    --Color-Grey-Back: #dbdbdb;
    --Color-Grey-Hover: #808080;
    --Color-Rojo-Logo: #e31c25;
    --Color-Verde: #20bf6b;
    --Color-Verde-Button: #BFE8C3;
    --Color-Verde-Texto: #00713b;
    --Color-Verde-Border: #BFE8C3;
    --Color-Verde-Hover: #26de81;
    --Color-White: #fff;
}



p{
    margin-bottom: 0 !important;
}

/* SPINNER */
.loading {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .loading div {
    position: absolute;
    border: 4px solid #00713B;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .loading div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
